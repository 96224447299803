// form submit button
.btn-submit {
    background: var(--primary);
    border-radius: 4px;
    border: none;
    padding: 6px 24px;
    font-size: 16px;
    display: block;
    color: white;
    width: 100%;
    text-transform: uppercase;
}

// .form-wrapper {
//     font-size: 18px;
//     line-height: 136%;
//     letter-spacing: 0.01em;
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 24px;
//     width: calc(50% - 20px);
//     box-sizing: border-box;
//     border-radius: 8px;
//     box-shadow: 1px 1px 6px lightgray;
//     padding: 20px;
// }

// .search-field-row {
//     display: flex;
//     align-items: center;
//     max-width: 100%;
// }

// .mat-form-field {
//     flex-basis: 50%;
//     margin-right: 10px;
// }

// .mat-select,
// .mat-input-element {
//     width: 100%;
// }

// .forms-row {
//     width: 100%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
// }

@media only screen and (max-width: 600px) {
    .forms-row {
        flex-direction: column;

        .form-wrapper {
            width: 100%;
        }
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 22px;
}

.mat-progress-spinner {
    margin: auto;
}

.my-purchase {
    &-main {
        width: calc(100% - 40px);
        min-height: 100%;

        &__stepper {
            margin: auto auto 90px;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;

            &-element {
                width: 180px;
                display: flex;
                flex-direction: column;
                position: relative;

                &-name {
                    width: 111px;
                    text-align: center;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    font-feature-settings: 'liga' off;
                    color: #f2f2f2;
                    position: absolute;
                    top: calc(100% + 4px);
                    left: -42px;

                    &.light {
                        color: #bcc3d0;
                    }

                    &.active {
                        color: var(--primary);
                    }

                    &.ready {
                        color: var(--primary);
                    }
                }

                &:last-child {
                    width: 32px;
                }
            }

            &-point {
                width: 32px;
                height: 32px;
                border: 2px solid #bcc3d0;
                box-sizing: border-box;
                border-radius: 90px;
                position: relative;
                font-weight: 500;
                font-size: 16px;
                line-height: 26px;
                color: #bcc3d0;
                display: flex;
                flex-direction: row;
                justify-content: center;

                &.active {
                    border: 2px solid var(--primary);

                    &::after {
                        background: var(--primary);
                    }
                }

                &.ready {
                    border: 2px solid var(--primary);

                    &::before {
                        background: var(--primary);
                    }

                    &::after {
                        background: var(--primary);
                    }
                }

                &.first {
                    &::after {
                        width: 0;
                        height: 0;
                    }
                }

                &.last {
                    &::before {
                        width: 0;
                        height: 0;
                    }
                }

                &::after {
                    content: '';
                    width: 74px;
                    height: 2px;
                    background: #bcc3d0;
                    position: absolute;
                    top: 50%;
                    right: calc(100% + 2px);
                }

                &::before {
                    content: '';
                    width: 74px;
                    height: 2px;
                    background: #bcc3d0;
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 2px);
                }
            }
        }

        &__main {
            box-sizing: border-box;
            border-radius: 4px;
            height: auto;
            background-color: #fff;

            &-header {
                width: 100%;
                font-weight: 600;
                font-size: 32px;
                line-height: 42px;
                letter-spacing: 0.01em;
                font-feature-settings: 'liga' off;
                color: #ffffff;

                &.light {
                    color: var(--primary);
                }
            }

            &-body {
                min-height: calc(100% - 90px);
                font-size: 18px;
                line-height: 136%;
                letter-spacing: 0.01em;
                color: #f2f2f2;
            }
        }
    }
}

.transactionPendingHelpText {
    height: 50px;
    background-color: #757575;
    border-radius: 3px;
    margin: 8px 0;
    align-items: center;
    display: inline-flex;
    width: 100%;

    &.light {
        background-color: #e8e8e8;
    }
}

.detailLink {
    text-decoration: underline;
    color: var(--primary);
    margin-left: 10px;
    cursor: pointer;
}

.buttonShowCurrentState {
    border-radius: 3px;
}

.showTransaction {
    margin-left: 30px;
}

.infoIconBox {
    margin: 0 10px;
}

.transactionPendingTextBox {
    color: white;

    &.light {
        color: #2a3c73;
    }
}

.transactionTableContainer {
    width: 100%;
    margin: 20px 0 10px 0;
    border-radius: 4px;
    overflow-x: scroll;
}

.TransactionTable {
    width: 100%;
    height: auto;
    margin: 0 auto;
    min-width: 600px;
}

.TransactionTableHeader {
    width: 100%;
    height: 50px;
    display: inline-flex;
}

.TransactionTableHeaderBox {
    color: #000;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 10px;
    font-weight: 600;
}

.ForEachContainer {
    width: 100%;
    display: inline-flex;
    height: 100%;
    display: table-row;
    color: #18213d;
    background-color: #fff;
    border: 1px solid #dae7ff;
}

.TransactionTableBody {
    width: 100%;
    height: 50px;
    display: table;
}

.TableHeaderRow {
    width: 100%;
    display: inline-flex;
    background-color: #d1e2ff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.TableRow {
    width: 100%;
    height: 50px;
    display: inline-flex;
    color: 000;
    border: 1px solid #dae7ff;
    border-top: none;
}

.TableRow:hover {
    background-color: #f0f5ff;
}

.TransactionTableBodyBox {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 10px;
}

.noTransactions {
    margin: 20px auto;
    color: #ffffff;
    text-align: center;
    font-size: 20px;

    &.light {
        color: #0e152a;
    }
}

.REJECTED {
    text-align: center;
}

//Spinner
.spinnerCenterAlignContainer {
    display: flex;
    height: 100%;
}

.spinnerContainer {
    display: flex;
    width: 300px;
    height: 100px;
    margin: 100px auto;
    align-self: center;
}

.dotContainer {
    width: 33%;
    display: flex;
    flex-direction: row;
}

@keyframes loadFirst {
    from {
        width: 50%;
        height: 50%;
        opacity: 1;
    }

    to {
        width: 25%;
        height: 25%;
        opacity: 0.1;
    }
}

.dot-left {
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    margin: 0 auto;
    align-self: center;
    border-radius: 100%;
    animation: loadFirst 0.8s ease-in;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.dot-middle {
    background-color: var(--primary);
    border-radius: 100%;
    width: 50px;
    height: 50px;
    margin: 0 auto;
    align-self: center;
    animation: loadFirst 0.8s ease-in;
    animation-iteration-count: infinite;
    animation-delay: 0.5s;
    animation-direction: alternate;
}

.dot-right {
    width: 33%;
    background-color: var(--primary);
    width: 50px;
    height: 50px;
    margin: 0 auto;
    align-self: center;
    border-radius: 100%;
    animation: loadFirst 0.8s ease-in;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    animation-direction: alternate;
}

@media only screen and (max-width: 800px) {
    .my-purchase-main__stepper-element-name {
        // top: calc(100% + 55px);
        // transform: rotate(270deg);
    }

    .my-purchase-main__stepper {
        margin: auto auto 8em;
    }
}

@media only screen and (max-width: 764px) {
    .my-purchase-main__stepper-point::after {
        width: 35px;
    }

    .my-purchase-main__stepper-point::before {
        width: 35px;
    }
}

@media only screen and (max-width: 650px) {
    .my-purchase-main {
        padding: 0;
        margin-left: 0;
        width: 100%;
    }

    .my-purchase-main__main {
        padding: 0;
    }
}

@media only screen and (max-width: 600px) {
    .transactionPendingHelpText {
        height: auto;
        padding: 10px 15px;
    }
}

@media only screen and (max-width: 570px) {
    .my-purchase-main__stepper {
        display: none;
    }
}

@media only screen and (max-width: 350px) {
    .my-purchase-main__main-header {
        font-size: 22px;
        line-height: 32px;
    }
}

@media only screen and (max-width: 300px) {
    .transactionPendingTextBox {
        word-break: break-all;
    }
}

.stepper {
    svg > * {
        &.primary-stroke {
            stroke: var(--primary);
        }

        &.primary-fill {
            fill: var(--primary);
        }
    }
}

.input-15 {
    width: 15%;
}

.input-20 {
    width: 20%;
}

.input-30 {
    width: 30%;
}

.input-40 {
    width: 40%;
}

.input-60 {
    width: 60%;
}

.input-70 {
    width: 70%;
}

.input-80 {
    width: 80%;
}

.form-row {
    width: 100%;
    display: flex;
}

.margin-0 {
    margin: 0;
}

.wrapper {
    max-width: 800px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(6, minmax(10%, 100%));
    gap: 5px;

    .full-width {
        grid-column: 1/7;
    }
}

.left-input {
    grid-column: 1/5;

    .full-width {
        width: 100%;
    }
}

.right-input {
    grid-column: 5/7;
}

@media only screen and(min-width: 800px) and (max-width: 1200px) {
    .wrapper {
        grid-template-columns: repeat(2, minmax(10%, 100%));
    }

    .left-input {
        grid-column: 1/4;
    }

    .right-input {
        grid-column: 4/7;
    }

    .remark-container {
        grid-column: 1/7;
    }
}

@media only screen and(max-width: 800px) {
    .wrapper {
        grid-template-columns: repeat(1, minmax(100%, 100%));

        .remark-input {
            width: 80%;
        }

        .remark-label {
            width: 20%;
        }

        .remark-container {
            grid-column: 1;
        }
    }

    .left-input {
        grid-column: 1;

        &.padding-right-75rem {
            padding-right: 0.5rem;
        }
    }

    .right-input {
        grid-column: 1;
        padding-right: 0.5rem;

        .padding-left-05rem {
            padding-left: 0;
        }
    }
}

@media only screen and(min-width: 1200px) {
    .remark-container {
        grid-column: 1/7;
    }
}

.remark-input {
    width: 80%;
}

.remark-label {
    width: 20%;
}

.input-3-container {
    width: 80%;
    display: flex;
}

// .wrapper,
// .form-wrapper {
//     .mat-form-field-infix {
//         padding: 0.4em 0 0.6em 0 !important;
//         border-top: 0.44375em solid transparent;
//     }
// }

// .mat-form-field-wrapper {
//     padding-bottom: 0 !important;
// }

.address-form-btn {
    margin-right: 0 !important;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
}

.full-width.align-right {
    display: flex;
    justify-content: flex-end;
    /* Align the content to the right */
    gap: 2em;
    /* Create a gap of 2em between the buttons */
}

.button-container {
    display: flex;
    gap: 2em;
    /* Create a gap of 2em between the buttons */
}

.large-form {
    max-width: 900px;
    margin: auto;
}

.input-suffix {
    padding-bottom: 0.1em;
}

// .form-wrapper,
// .money-input {
//     .mat-form-field.mat-form-field-appearance-outline .mat-input-element {
//         text-align: right;

//         &.align-left {
//             text-align: left;
//         }
//     }
// }

.input-container {
    display: flex;
    /* Display children in a row */
    align-items: center;
    /* Vertically center the content */
}

.mat-form-field-outline {
    width: 100%;
    /* Ensure the outline takes full width */
}
