body,
html {
    font-family: var(--primary-font) !important;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.align-left {
    text-align: left;
}

.align-right {
    text-align: right !important;
}

.align-center {
    text-align: center;
}

.black {
    color: #000;
}

ul {
    list-style-type: none;
    margin-top: 4px;
}

.padding-right-75rem {
    padding-right: 0.75rem;
}

.padding-right-1rem {
    padding-right: 1rem;
}

.padding-right-2rem {
    padding-right: 0.75rem;
}

.padding-left-05rem {
    padding-left: 0.5rem;
}

.padding-left-75rem {
    padding-left: 0.75rem;
}

.padding-left-1rem {
    padding-left: 1rem;
}

.padding-left-2rem {
    padding-left: 2rem;
}

.margin-bottom-2rem {
    margin-bottom: 2rem;
}

.margin-top-2rem {
    margin-top: 2rem;
}

.margin-top-1rem {
    margin-top: 2rem;
}

.margin-left-2rem {
    margin-left: 2rem;
}

.margin-bottom-1rem {
    margin-bottom: 1rem;
}

.margin-bottom-05rem {
    margin-bottom: 0.5rem;
}

.margin-right-05rem {
    margin-right: 0.5rem;
}

.margin-right-1rem {
    margin-right: 1rem;
}

.margin-right-2rem {
    margin-right: 2rem;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.bold {
    font-weight: bold;
}
.right-align {
  text-align: right;
}
.tabbed {
    padding: 0 20px;
}
.slim {
    font-weight: normal;
}

.button {
    &.dialog {
        min-width: 70px;

        &.yes {
            margin-right: auto;
        }

        &.no {
            margin-left: auto;
        }
    }
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.overlay-panel {
    overflow: scroll;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    z-index: 4;

    &__panel {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background: #1d2640;
        z-index: 5;
        padding: 3% 0 0 4%;

        &.light {
            background-color: white;
        }

        &-header {
            padding: 20px 40px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-name {
                display: flex;
                flex-direction: row;
                font-weight: 600;
                font-size: 30px;
                line-height: 136%;
                color: #f2f2f2;

                &.light {
                    color: #131c37;
                }

                .avatar {
                    margin-right: 16px;
                    padding: 8px 0;
                    width: 40px;
                    height: 40px;
                    background: linear-gradient(151.88deg, #73b1d0 26.63%, #4d5f97 87.37%);
                    border-radius: 100%;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 24px;
                    color: #f2f2f2;
                    text-align: center;
                }
            }

            &-close {
                margin: 4px 0;
                padding: 5px;
                cursor: pointer;
                color: #000;
                display: inline;
            }

            &.light {
                color: #000;
            }

            &-close:hover {
                font-weight: 500;

                svg {
                    path {
                        stroke: var(--primary);
                        stroke-width: 1.5px;
                    }
                }
            }
        }

        &-tabs {
            ::ng-deep .mat-tab-list {
                padding: 0 20px;
                background: #131c37;
            }

            &.light {
                ::ng-deep .mat-tab-list {
                    padding: 0 20px;
                    background: #e8ebec;
                }

                ::ng-deep .mat-tab-label-content {
                    color: #bcc3d0;
                }

                ::ng-deep .mat-ink-bar {
                    background: transparentize(#73b1d0, 0.3);
                }

                ::ng-deep .mat-tab-label-active > .mat-tab-label-content {
                    color: #131c37;
                }
            }

            &-pane {
                padding: 40px 10px 0 40px;

                .header {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    &-name {
                        font-weight: 600;
                        font-size: 30px;
                        line-height: 136%;
                        color: #f2f2f2;
                    }
                }
            }
        }
    }
}

.grayoutzoneToClosePanel {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 4;
}

::ng-deep .mat-tab-label-content {
    font-size: 20px;
    line-height: 26px;
    color: #4a5165;
}

::ng-deep .mat-tab-label {
    padding: 0 20px;
    opacity: 1 !important;
}

::ng-deep .mat-tab-label-active {
    ::ng-deep .mat-tab-label-content {
        color: #fff;
        font-weight: 600;
    }
}

.font-size-28 {
    font-size: 28px;
}

.font-size-24 {
    font-size: 24px;
}

.mat-date-range-input-container {
    font-size: 14px;
    padding: 1px 0;
}

.form-separator {
    margin: 0.75rem auto;
    max-width: 800px;
}

.separator {
    margin: 1rem 0;
}

.vertical-align {
    display: flex;
    align-items: center;
}

.filter-container {
    max-width: 100%;
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(5, minmax(10%, 100%));

    .mat-form-field {
        width: 100%;
    }
}

@media screen and (min-width: 600px) and (max-width: 1550px) {
    .filter-container {
        grid-template-columns: repeat(4, minmax(10%, 100%));
    }
}

@media screen and (max-width: 600px) {
    .filter-container {
        grid-template-columns: repeat(2, minmax(10%, 100%));
    }
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: var(--primary) !important;
}

.primary {
    color: var(--primary);
}

.secondary {
    color: var(--secondary);
}

.bg-primary {
    background-color: var(--primary);
}

.bg-secondary {
    background-color: var(--secondary);
}

.centered {
    margin: auto;
}

.red {
    color: var(--hover);
}

.flex {
    display: flex;
}

.margin-auto {
    margin: auto;
}

.address-separator {
    width: 80%;
    margin: auto;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.color-light {
    color: lightgrey;
}

.app-main {
    min-height: 100vh;
}

.inline-flex {
    display: inline-flex;
}

.vertical-align-middle {
    vertical-align: middle;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.grey {
    color: rgba(0, 0, 0, 0.38) !important;
}

.snackbar--default div.mdc-snackbar__surface {
    background-color: midnightblue;
    color: white;
}

/* Success Snackbar Styles */
.success-snackbar {
    background-color: #edf7ed;
    color: var(--green-dark);
}

/* Error Snackbar Styles */
.error-snackbar {
    background-color: #fdecea;
    color: var(--red-dark);
}

.display-none {
    display: none !important;
}

.padding-left-24 {
    padding-left: 24px !important;
}
