body {
    background-color: #f9fbff;

    &.light {
        background-color: transparent;
    }
}

label {
    margin-bottom: 0;
}

a,
a:hover {
    text-decoration: none;
}

input {
    border: none;

    &:focus {
        outline: none;
    }
}

.nav-item {
    height: 160px;
    border: 1px solid #2a3c73;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 24px 32px;
    font-family: 'IBM Plex Sans', sans-serif;
    transition: all linear 0.3s;
    overflow: hidden;

    &--active {
        background: #1a2649;
        border: none;
        border-bottom: 4px solid #4c68a6;
    }

    &--small {
        height: 75px;
        overflow: hidden;

        :not(.nav-item__title) {
            display: none !important;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        color: #ffffff;
        padding-bottom: 11px;
    }

    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #a8b6e1;
        padding-bottom: 2px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}

.module-title {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 42px;
    color: #ffffff;
}

.swarm-btn {
    padding: 4px 16px;
    border-radius: 4px;
    border: none;
    text-align: center;
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #131c37;
    text-transform: uppercase;
    cursor: pointer;
    outline: none !important;

    &--blue {
        background: #73b1d0;
    }

    &__img {
        margin-right: 4px;
    }
}

.margin-right-24 {
    margin-right: 24px;
}

.margin-right-17 {
    margin-right: 17px;
}

.mat-calendar-arrow {
    border-top-color: #f2f2f2;
}

.mat-calendar-controls,
.mat-calendar-body-cell-content,
.mat-calendar-body-label,
.mat-calendar-table-header {
    // color: #f2f2f2;
}

.mat-datepicker-content {
    // background: #11182d;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1), 0 24px 48px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    // color: #f2f2f2;
    font-family: 'IBM Plex Sans', sans-serif !important;

    .mat-calendar-previous-button,
    .mat-calendar-next-button {
        color: #f2f2f2;
    }

    // .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    // border-color: rgba(#f2f2f2, 0.5);
    // }

    .mat-calendar-body-selected {
        background-color: #73b1d0;
        border-radius: 4px;
        color: #e8ebec;
        border: none;
    }
}

// ::-webkit-scrollbar{
//   background: transparent;
// }

.light {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: none;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.14);
    }

    ::-webkit-scrollbar {
        width: 4px;
        background-color: rgba(0, 0, 0, 0.18);
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: none;
        background: rgba(0, 0, 0, 0.14);
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        -ms-border-radius: 10px;
        -o-border-radius: 10px;
    }
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background: #252b3e;
}

::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    background: rgba(255, 255, 255, 0.3);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.blur-currency {
    width: fit-content;
    filter: blur(10px);
    user-select: none;
}

mat-slider {
    color: var(--primary);
}

.mat-slider-has-ticks.cdk-focused:not(.mat-slider-disabled) .mat-slider-ticks,
.mat-slider-has-ticks:hover:not(.mat-slider-disabled) .mat-slider-ticks {
    opacity: 0 !important;
}

.mat-slider-thumb-label,
.mat-slider-track-background,
.mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-track-fill {
    background-color: #646567 !important;
}

.mat-slider.mat-accent .mat-slider-thumb {
    background-color: #646567 !important;
    border-color: transparent !important;
    transform: scale(1.2);
}

.mat-slider.mat-accent .mat-slider-thumb-label {
    background-color: #646567 !important;
    width: 35px;
    height: 35px;
    top: -44px;
}

.mat-slider.mat-accent .mat-slider-track-fill {
    background-color: #646567 !important;
}

.mat-slider-has-ticks.cdk-focused:not(.mat-slider-hide-last-tick) .mat-slider-wrapper::after,
.mat-slider-has-ticks:hover:not(.mat-slider-hide-last-tick) .mat-slider-wrapper::after {
    opacity: 0 !important;
}

.mat-form-field-wrapper {
    height: 40px;
    margin: 0px;
    padding: 0;
}
.mat-form-field-flex {
    height: 40px;
}
.mat-form-field-infix {
    padding: 2px 0 1em 0 !important;
}
.mat-form-field-appearance-outline .mat-form-field-suffix {
    align-self: center;
}
.multi-line-snackbar {
    white-space: pre-wrap;
}

::ng-deep
    .mat-calendar-body-disabled
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    text-decoration: line-through !important;
}
