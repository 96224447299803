// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@import 'app/shared/styles/_table.scss';
@import 'app/shared/styles/_common.scss';
@import 'app/shared/styles/_forms.scss';
@import 'app/shared/styles/_overlay-panel.scss';
@import 'app/shared/styles/_brand.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$swarm-primary: mat.define-palette(mat.$indigo-palette);
$swarm-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$swarm-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$swarm-theme: mat.define-light-theme(
    (
        color: (
            primary: $swarm-primary,
            accent: $swarm-accent,
            warn: $swarm-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($swarm-theme);

@import 'src/assets/styles/grid.scss';
@import 'src/assets/styles/common.scss';
@import 'src/assets/styles/vars.scss';
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500;600&display=swap');

//@import '~swiper/swiper';

.swiper-container {
    overflow: visible;
}
.swiper-pagination-bullet {
    margin-right: 8px;
    background: #23315c;
    opacity: 1;
    &-active {
        background: #4c68a6;
        opacity: 1;

        &.light {
            background: #2a3c73;
        }
    }
    &:last-child {
        margin-right: 0;
    }
    &.light {
        background: #bcc3d0;
    }
}

html,
body {
    height: auto;
}
body {
    margin: 0;
    font-family: 'IBM Plex Sans', Roboto, 'Helvetica Neue', sans-serif;
    font-feature-settings: 'liga' off;
}
p {
    margin: 0;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}
.mat-form-field-appearance-outline {
    .mat-form-field-outline-thick {
        color: var(--primary);
    }
    &.mat-focused {
        .mat-form-field-outline-thick {
            color: var(--primary);
        }
    }
}

.mat-option.mat-selected:not {
    color: var(--primary);
}
.mat-ripple-element {
    color: var(--primary);
}
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
    color: var(--primary);
}
.mat-form-field-appearance-standard .mat-form-field-underline {
    background-color: #fff;
}
.mat-form-field-ripple {
    background-color: #fff;
}
.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: #fff;
}
.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
    background: var(--primary);
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:hover .mat-form-field-outline {
    color: var(--primary);
}
.mat-form-field-appearance-outline:not(.mat-form-field-disabled) .mat-form-field-flex:visited .mat-form-field-outline {
    color: var(--primary);
}
.mat-form-field.mat-focused .mat-form-field-label {
    color: var(--primary);
}
.mat-form-field-appearance-outline .mat-form-field-label {
    color: var(--primary) !important;
    margin-bottom: 10px;
}
.mat-form-field-label:not(.mat-form-field-empty) {
    color: var(--primary);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
    background-color: #fff;
}
.mat-select-disabled .mat-select-value {
    // color: #000 !important;
}

.attaches {
    width: 40%;
    margin: 0.5em 0;

    @media only screen and (max-width: 768px) {
        width: 100%;
    }
}

.cdx-block {
    padding: 0;
    font-size: 18px;
}

.columns {
    display: flex;
    gap: 1em;

    @media only screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.column1,
.column2 {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.paragraph {
    width: fit-content;
}

.column1,
.column2 {
    align-items: start;
    align-content: start;
    flex: 1;
}

.container-img img {
    max-width: 100%;
    height: auto;
}
.img-parser,
img {
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.image-tool__caption {
    display: none;
}

.button {
    padding: 8px 24px;
    color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 8px;
    font-size: 18px;
    font-weight: 600;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.button:hover {
    background-color: var(--hover) !important;
    border-color: var(--hover) !important;
    color: #fff !important;
}

.button:disabled {
    opacity: 0.5;
    cursor: auto;
}

:focus-visible {
    outline: none;
    border-color: inherit;
}

.page-wrapper {
    padding: 2rem 5rem;
    max-width: 1600px;

    @media only screen and (max-width: 1200px) {
        padding: 2rem 4rem;
    }

    @media only screen and (max-width: 768px) {
        padding: 2rem 0;
    }
}
